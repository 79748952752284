import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import s from './UserNav.module.css';

const linksOptions = [
  {
    text: 'Home',
    to: '/',
  },
  {
    text: 'Dashboard',
    to: '/dashboard',
  },
  {
    text: 'Diary',
    to: '/diary',
  },
  {
    text: 'Recommended',
    to: '/recommended-food',
  },
];

const UserNav = () => {
  return (
    <nav className={s.nav}>
      <ul className={s.list}>
        {linksOptions.map(el => (
          <li key={el.to} className={s.item}>
            <NavLink
              className={({ isActive }) => clsx(s.link, isActive && s.active)}
              to={el.to}
            >
              {el.text}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default UserNav;
